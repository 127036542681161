<script setup lang="ts">
import type { Property } from '../../../../../api/node_modules/aeria'
import { action, setupAction } from './action'
import { getSpanPanel } from './store'

definePage({
  meta: {
    title: 'Integração',
    icon: 'link',
    collection: 'integration'
  }
})

const metaStore = useStore('meta')
const integrationStore = useStore('integration')
const orderStore = useStore('order')

setupAction()

const visible = ref(false)

const mappings = ['orderpaid',
              'orderupdated']

const getSpanForm: Record<string, Property> = {
  mapping: {
    translate: true,
    enum: mappings,
    hint: 'Filtro dos pedidos a serem buscados',
  },
  date_min: {
    type: 'string',
    format: 'date',
    translate: true
  },
  date_max: {
    type: 'string',
    format: 'date',
    translate: true
  }
}

const formData = reactive({
  integration_id: '',
  mapping: 'orderpaid',
  date_min: new Date(),
  date_max: new Date(),
  token: ''
})

const handleUiEvent = (event: any) => {
  if( event.name === 'getSpan' ) {
    visible.value =  true
    formData.integration_id = event.params._id
    integrationStore.$actions.setItem(event.params)
  }
}

const search = async () => {
  await orderStore.$functions.getSpan(formData)
  metaStore.$actions.spawnModal({
    title: 'Sucesso',
    body: 'Tarefa adicionada à fila'
  })
}

const getSpan = async () => {
  await orderStore.$functions.getSpan(integrationStore.getSpan)
  metaStore.$actions.spawnModal({
    title: 'Sucesso',
    body: 'Tarefa adicionada à fila'
  })
}
</script>

<template>
  <aeria-crud
    collection="integration"
    :action="action"
    @ui-event="handleUiEvent"
  >
    <template #actions>
      <aeria-button
        icon="gear"
        @click="getSpanPanel = true"
      >
        Buscar faixa de pedidos
      </aeria-button>
    </template>
    <template #row-platform="{ row, column }">
      <div class="
        tw-flex
        tw-items-center
        tw-gap-1
      ">
        <extra-icon :name="row.platform"></extra-icon>
        <div>
          {{ row[column] }}
        </div>
      </div>
    </template>

    <template #row-shipping_profile="{ row, column }">
      <div class="
        tw-flex
        tw-items-center
        tw-gap-1
      ">
        <extra-icon v-if="row[column]" :name="row.shipping_profile?.provider"></extra-icon>
        <div>{{ row[column]?.name || '-' }}</div>
      </div>
    </template>

  </aeria-crud>

  <aeria-panel
    float
    close-hint
    title="Busca retroativa"
    v-model="visible"
    @overlay-click="visible = false"
  >
    <header class="tw-mb-12">Selecionado: {{ integrationStore.item.name }}</header>
    <aeria-form
      v-model="formData"
      v-bind="{
        form: {
          mapping: {
            enum: [...mappings, 'ordertoken'],
            translate: true,
            hint: 'Filtro dos pedidos a serem buscados'
          },
        },
      }"
    ></aeria-form>
    <aeria-form
      v-if="formData.mapping !== 'ordertoken'"
      v-model="formData"
      v-bind="{
        form: {
          date_min: {
            type: 'string',
            format: 'date'
          },
          date_max: {
            type: 'string',
            format: 'date'
          }
        },
      }"
    ></aeria-form>
    <aeria-input
      v-else
      v-model="formData.token"
      >Token</aeria-input>
    <template #footer>
      <aeria-button large @click="search">Buscar</aeria-button>
    </template>
  </aeria-panel>

  <aeria-panel
    close-hint
    fixed-right
    title="Buscar faixa de pedidos"
    v-model="getSpanPanel"
    @overlay-click="getSpanPanel = false"
  >
    <aeria-form
      v-model="integrationStore.getSpan"
      v-bind="{
        form: getSpanForm,
      }"
    ></aeria-form>

    <template #footer>
      <aeria-button
        variant="transparent"
        @click="getSpanPanel = false"
      >
        Cancelar
      </aeria-button>
      <aeria-button
        large
        @click="getSpan"
      >
        Buscar
      </aeria-button>
    </template>
  </aeria-panel>
</template>
