import { Result } from '@aeriajs/types'
import type { Order } from '../../../api/src/collections/order/description'
import type { OrderInsertReturnType } from '../../../api/src/collections/order/insert'
import type { UpdateTracking } from '../../../api/src/collections/order/updateTracking'
import { registerStore, useStore, createCollectionStore, t } from 'aeria-ui'
import { reactive } from 'vue'
import { aeria } from 'aeria-sdk'

export const createOrderStructure = () => {
  const initialState = reactive({
    orderHistory: [] as Array<Order> | Array<string>,
    generation: {
      mapping: 'orderpaid',
      date_min: 0 as string | number,
      date_max: 0 as string | number,
      platforms: []
    },
    failedOrdersCount: 0
  })

  const getters = (state: typeof initialState) => ({
    $history: computed(() => {
      if( state.orderHistory.length === 0 ) {
        const stored = localStorage.getItem('order:history')
        const orderHistory: Array<Order> = stored
          ? JSON.parse(stored)
          : []

        state.orderHistory = orderHistory
      }
      return state.orderHistory
    }),
    $generation: computed(() => {
      const generation = Object.assign({}, state.generation)
      const now = new Date()

      const daysDiff = (_d1: string, _d2: string | Date) => {
        const d1 = new Date(_d1)
        const d2 = _d2 instanceof Date ? _d2 : new Date(_d2)
        return Math.floor((d1.getTime() - d2.getTime())/(1000*3600*24))
      }

      generation.date_min = generation.date_min
        ? daysDiff(String(generation.date_min), now)
        : -1

      generation.date_max = generation.date_max
        ? daysDiff(String(generation.date_max), String(state.generation.date_min) || new Date)
        : generation.date_min * -1

      return generation

    })
  })

  return {
    state: initialState,
    getters,
  }
}

export const order = registerStore((context) => {
  const { state, getters } = createOrderStructure()
  const metaStore = useStore('meta', context.manager)
  const router = useRouter()
  return createCollectionStore({
    $id: 'order',
    state,
    getters,
    actions: (state, actions) => ({
      generate() {
        return actions.custom('generate', state.generation)
      },
      async countFailedOrders() {
        if (state.failedOrdersCount < 1) {
          const { result }: any = await actions.count({
            filters: {
              error: {
                $ne: null
              }
            }
          })

          state.failedOrdersCount = result
        }
      },
      async sendForReview(filters: Pick<Order, '_id' | 'status'>) {
        if( filters.status === 'in_review' ) {
          throw new Error('Pedido já enviado para revisão')
        }

        await actions.insert({
          what: {
            _id: filters._id as unknown as string,
            status: 'in_review',
            sent_for_review: true,
            previous_status: filters.status
          }
        })
      },
      async unboundShipment(filters: Pick<Order, '_id'>) {
        const either = await actions.custom<Result.Either<unknown, unknown>>('unboundShipment', { filters })

        if( either.result ) {
          actions.insertItem(either.result)
        }

        return either
      },
      async updateTracking(payload: Parameters<UpdateTracking>[0]) {
        const either: Awaited<ReturnType<UpdateTracking>> = await actions.custom('updateTracking', payload)

        if (either.result) {
          metaStore.$actions.spawnToast({
            text: `Rastreio enviado para plataforma e notificação enviada para ${payload.customer.name}.`,
            icon: <const>'envelope'
          })
        } else {
          metaStore.$actions.spawnToast({
            text: `Algum erro ocorreu ao disparar o rastreio.`,
            icon: 'x-circle'
          })
        }

        return either
      },
      async insert(payload: { what: Order }) {
        // Responsible for notifying the user the tracking update status if he edited the tracking_code

        const insertEither: Awaited<OrderInsertReturnType> = await actions.custom('insert', payload)

        if (insertEither.error && insertEither.error.code === "MISSING_PROVIDER") {
          metaStore.$actions.spawnModal({
            title: "Ops...",
            body: "Ao editar o rastreio é necessário colocar a transportadora.",
          })
          
          return insertEither
        }

        const { result } = insertEither
        if (result ) {
          actions.insertItem(result)
          if( 'tracking_updated' in result ) {
            if (result.tracking_updated === true)
            {
              metaStore.$actions.spawnToast({
                text: `Rastreio enviado para plataforma e notificação enviada para ${result.customer.name}.`,
                icon: <const>'envelope'
              })
            } else {
              metaStore.$actions.spawnToast({
                text: `Algum erro ocorreu ao disparar o rastreio.`,
                icon: 'x-circle'
              })
            }
          }
        }

        return insertEither
      },
      async makeReshipment(filters: Pick<Order, '_id'>) {
        /* const reshipmentStore = useStore('reshipment', context.manager) */
        const orderStore = useStore('order', context.manager)
        const { error, result: order } = await orderStore.$actions.get({
          filters: {
            _id: filters._id
          }
        })

        if( error ) {
          console.trace(error)
          return
        }

        /* const item = {
          customer: order.customer,
          products: order.products,
          plans: order.plans,
          shipping_profile: order.shipping_profile,
          shipping_service: order.shipping_service,
          shipping_address: order.shipping_address,
        }

        reshipmentStore.$actions.setItem(item) */

        const params = {
          //weight: order.,
          declared_value: order.shipping_cost as unknown as string,
          //height: '0',
          //width: '0',
          content_description: order.content_description ?? '',
          receiver_name: order.customer.name,
          receiver_email: order.customer.email,
          receiver_phone: order.customer.phone,
          receiver_document: order.customer.document,
          receiver_country: order.shipping_address?.country,
          receiver_address: order.shipping_address?.address,
          receiver_number: order.shipping_address?.number,
          receiver_district: order.shipping_address?.district,
          receiver_state: order.shipping_address?.state,
          receiver_zipcode: order.shipping_address?.zipcode,
          receiver_city: order.shipping_address?.city,
          receiver_complement: order.shipping_address?.complement ?? '',
          products: order.products.map((p: any) => p._id),
          order_token: order.token,
        } as any
        if (order.integration?._id)
        {
          params.integration = order.integration._id.toString()
        }
        const queryFields = new URLSearchParams(params).toString()
        ROUTER.push(`/dashboard/manual-posting?${queryFields}&modality=${params.products?.length > 0 ? "products" : "content"}`)
      },

      async getTracking(payload: { document: Order['customer']['document'] }) {
        const { error, result }: any = await actions.custom('getTracking', payload)

        if(error) {
          localStorage.clear()
          return Result.error(error)
        }

        state.items = result
        return Result.result("found")
      },

      async pushToHistory() {
        await aeria.order.pushToHistory.POST({
          order_id: state.item._id,
        })
      },

      clearHistory() {
        localStorage.removeItem('order:history')
        state.orderHistory = []
      },

      async copyToClipboard() {
        const {
          _id,
          __v,
          created_at,
          updated_at,
          events,
          sent_for_revision,
          ...order

        } = state.item

        const properties = Object.keys(state.description.properties)
        order.status = t(order.status)

        const result: string = Object.entries(order)
        .sort((a: any, b: any) => properties.indexOf(a[0]) > properties.indexOf(b[0]) ? 1 : - 1)
        .reduce((a: string, [key, v]: any) => {
          if( !(key in state.description.properties) ) {
            return a
          }

          const description = state.description.properties[key].description||key
          const value = actions.formatValue({
            value: v,
            key,
            property: state.rawDescription.properties[key]
          })

          return a + `${description}: ${value}\n`

        }, '')

        await navigator.clipboard.writeText(result)
        // this.store('meta').spawnToast({
        //   text: 'Dados copiados!'
        // })
      },
      getComputedColor(status: Order['status']) {
        switch( status ) {
          case 'paid': return 'brand'
          // case 'cancelled':
          //   case 'refused':
          //   return 'red'
          // case 'waiting_payment': return 'yellow'
          default: return 'gray'
        }
      },
      viewIntegration: async (filters: Order) => {
        const integrationStore = useStore('integration', context.manager)
        integrationStore.filters._id = filters.integration?._id
        
        await integrationStore.$actions.filter()
        ROUTER.push({
          name: '/dashboard/integration/',
          query: {
            'integration._id': filters.integration?._id
          }
        })
      },
      viewCustomer: async (filters: Order) => {
        const customerStore = useStore('customer', context.manager)
        customerStore.filters._id = filters.customer._id
  
        await customerStore.$actions.filter()
        ROUTER.push({
          path: '/dashboard/customer/',
          query: {
            'customer._id': filters.customer._id
          }
        })
      }
    }),
  }, context)
})
